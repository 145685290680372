import React from "react";

import { IPrismicIqTest } from "../../prismic.model";

import { threePNG } from "../../../assets/images";

import * as styles from "./IqTest.module.scss";

interface IProps {
  data: {
    iqTestData: IPrismicIqTest;
  };
}

const IqTest = (props: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <img src={threePNG} alt="three" />
        <p>{props.data.iqTestData.headline.text}</p>
      </div>
      <div className={styles.explanationContainer}>
        <div className={styles.illustrationContainer}>
          <img
            src={props.data.iqTestData.iq_test_illustration.url}
            alt="iq test illustration"
          />
        </div>
        <div className={styles.explanation}>
          <p>{props.data.iqTestData.paragraph_1.text}</p>
          <p>{props.data.iqTestData.paragraph_2.text}</p>
          <p>{props.data.iqTestData.paragraph_3.text}</p>
        </div>
      </div>
    </div>
  );
};

export default IqTest;
