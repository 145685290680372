import React from "react";

import { IPrismicDrive } from "../../prismic.model";

import * as styles from "./MotivationTest.module.scss";

interface IProps {
  drive: IPrismicDrive;
}

const Drive = (props: IProps) => {
  const style = {
    backgroundColor: props.drive.color.text,
  };
  return (
    <div className={styles.drive}>
      <div className={styles.header}>
        <div className={styles.circle} style={style} />
        <div className={styles.label}>
          <p>{props.drive.title.text}</p>
        </div>
      </div>
      <div className={styles.explanation}>
        <p>{props.drive.explanation.text}</p>
      </div>
    </div>
  );
};

export default Drive;
