import React, { Component } from "react";
import { appInsights } from "../../components/AppInsights/AppInsights";

import PersonalityTest from "./PersonalityTest";
import MotivationTest from "./MotivationTest";
import IqTest from "./IqTest";

import {
  IPrismicScience,
  IPrismicPersonalityTest,
  IPrismicMotivationTest,
  IPrismicIqTest,
  IPrismicOceanTrait,
  IPrismicDrive,
} from "../prismic.model";

import * as styles from "./Science.module.scss";

interface IProps {
  scienceData: IPrismicScience;
  personalityTestData: IPrismicPersonalityTest;
  motivationTestData: IPrismicMotivationTest;
  iqTestData: IPrismicIqTest;
  traits: IPrismicOceanTrait[];
  drives: IPrismicDrive[];
}
export default class Science extends Component<IProps> {
  componentDidMount() {
    appInsights.trackPageView();
  }

  render() {
    const {
      scienceData,
      personalityTestData,
      traits,
      motivationTestData,
      drives,
      iqTestData,
    } = this.props;

    return (
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            <p className={styles.heading}>{scienceData.intro_heading.text}</p>
            <p className={styles.label}>{scienceData.intro_label.text}</p>
          </div>
          <div className={styles.headerIllustration}>
            <img
              src={scienceData.intro_illustration.url}
              alt="the science intro illustration"
            />
          </div>
        </div>
        <PersonalityTest data={{ personalityTestData, traits }} />
        <MotivationTest data={{ motivationTestData, drives }} />
        <IqTest data={{ iqTestData }} />
      </div>
    );
  }
}
