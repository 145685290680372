import React from "react";

import Drive from "./Drive";

import { twoPNG } from "../../../assets/images";

import { IPrismicMotivationTest, IPrismicDrive } from "../../prismic.model";

import * as styles from "./MotivationTest.module.scss";

interface IProps {
  data: {
    motivationTestData: IPrismicMotivationTest;
    drives: IPrismicDrive[];
  };
}
const MotivationTest = (props: IProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
        <img src={twoPNG} alt="two" />
        <p>{props.data.motivationTestData.headline.text}</p>
      </div>
      <div className={styles.explanationContainer}>
        <div className={styles.paragraphsAndIllustrationContainer}>
          <div className={styles.paragraphsContainer}>
            <p>{props.data.motivationTestData.paragraph_1.text}</p>
            <p>{props.data.motivationTestData.paragraph_2.text}</p>
          </div>
          <div className={styles.illustrationContainer}>
            <img
              src={
                props.data.motivationTestData.motivation_test_illustration.url
              }
              alt="motivation test illustration"
            />
          </div>
        </div>
        <div>
          <p>{props.data.motivationTestData.paragraph_3.text}</p>
          <p>{props.data.motivationTestData.paragraph_4.text}</p>
        </div>
      </div>
      <div className={styles.drivesContainer}>
        {props.data.drives.map((drive: IPrismicDrive) => (
          <Drive key={drive.ordinal_number} drive={drive} />
        ))}
      </div>
    </div>
  );
};

export default MotivationTest;
