import React, { Component } from "react";

import OceanTrait from "./OceanTrait";

import { onePNG } from "../../../assets/images";

import {
  IPrismicPersonalityTest,
  IPrismicOceanTrait,
} from "../../prismic.model";

import * as styles from "./PersonalityTest.module.scss";
import { PlusIcon } from "../../../assets/icons";
import OceanTab from "./OceanTab";

interface IProps {
  data: {
    personalityTestData: IPrismicPersonalityTest;
    traits: IPrismicOceanTrait[];
  };
}

interface IState {
  openedTrait: number;
}

class PersonalityTest extends Component<IProps, IState> {
  state = {
    openedTrait: 1,
  };
  render() {
    const { personalityTestData, traits } = this.props.data;
    return (
      <div className={styles.container}>
        <div className={styles.headerContainer}>
          <img src={onePNG} alt="one" />
          <p>{personalityTestData.headline.text}</p>
        </div>
        <div className={styles.explanationContainer}>
          <div className={styles.explanation}>
            <p>{personalityTestData.paragraph_1.text}</p>
            <p>{personalityTestData.paragraph_2.text}</p>
          </div>
          <div className={styles.illustrationContainer}>
            <img
              src={personalityTestData.personality_test_illustration.url}
              alt="personality test illustration"
            />
          </div>
        </div>
        <div className={styles.oceanTabsContainer}>
          {traits.map((trait: IPrismicOceanTrait) => (
            <OceanTab
              key={trait.ordinal_number}
              isActive={this.state.openedTrait === trait.ordinal_number}
              label={trait.title.text}
              ordinal_number={trait.ordinal_number}
              onSelect={this.handleToggleTrait}
            />
          ))}
        </div>
        <div className={styles.oceanTraitsContainer}>
          {traits.map((trait: IPrismicOceanTrait) => (
            <OceanTrait
              key={trait.ordinal_number}
              trait={trait}
              onToggleTrait={this.handleToggleTrait}
              isOpen={trait.ordinal_number === this.state.openedTrait}
            />
          ))}
        </div>
      </div>
    );
  }

  private handleToggleTrait = (traitOrdinalNumber: number) => {
    this.setState({
      openedTrait: traitOrdinalNumber,
    });
  };
}

export default PersonalityTest;
