import React from "react";
import * as styles from "./PersonalityTest.module.scss";
interface IProps {
  isActive: boolean;
  label: string;
  ordinal_number: number;
  onSelect: (ordinal_number: number) => void;
}

const OceanTab = (props: IProps) => {
  const handleClick = () => {
    props.onSelect(props.ordinal_number);
  };

  const getTabClass = () => {
    const classNames = [styles.oceanTab];
    if (props.isActive) {
      classNames.push(styles.active);
    }

    return classNames.join(" ");
  };

  return (
    <button type="button" className={getTabClass()} onClick={handleClick}>
      <p>{props.label}</p>
    </button>
  );
};

export default OceanTab;
