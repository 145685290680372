import React from "react";

import { IPrismicOceanTrait } from "../../prismic.model";

import * as styles from "./PersonalityTest.module.scss";
import { PlusIcon, MinusIcon } from "../../../assets/icons";

interface IProps {
  trait: IPrismicOceanTrait;
  onToggleTrait: (traitOrdinalNumber: number) => void;
  isOpen: boolean;
}

const OceanTrait = (props: IProps) => {
  const getTraitRangeStyle = () => {
    switch (props.trait.ordinal_number) {
      case 1:
        return styles.traitRange1;
      case 2:
        return styles.traitRange2;
      case 3:
        return styles.traitRange3;
      case 4:
        return styles.traitRange4;
      case 5:
        return styles.traitRange5;
    }
  };

  const handleClick = () => {
    props.onToggleTrait(props.trait.ordinal_number);
  };

  const getMobileHeaderClass = () => {
    const classNames = [styles.mobileHeader];
    if (props.isOpen) {
      classNames.push(styles.active);
    }

    return classNames.join(" ");
  };

  return (
    <>
      <div className={getMobileHeaderClass()} onClick={handleClick}>
        <p className={styles.label}>{props.trait.title.text}</p>
        {props.isOpen ? <MinusIcon /> : <PlusIcon />}
      </div>
      {props.isOpen && (
        <div className={styles.oceanTraitContainer}>
          <div className={styles.trait}>
            <div className={styles.headline}>
              <p>{props.trait.what_does_it_mean_label.text}</p>
            </div>
            <div className={styles.explanation}>
              <p>{props.trait.explanation.text}</p>
              <p>{props.trait.example.text}</p>
            </div>
          </div>
          <div className={styles.traitJobs}>
            <div className={styles.label}>
              <p>{props.trait.jobs_headline.text}</p>
            </div>
            <div className={styles.jobsContainer}>
              <div>
                <p>{props.trait.low_job.text}</p>
              </div>
              <div>
                <p>{props.trait.medium_job.text}</p>
              </div>
              <div>
                <p>{props.trait.high_job.text}</p>
              </div>
            </div>
            <div className={getTraitRangeStyle()} />
            <div className={styles.levelsContainer}>
              <div>
                <p className={styles.level}>{props.trait.low_label.text}</p>
              </div>
              <div>
                <p className={styles.level}>{props.trait.medium_label.text}</p>
              </div>
              <div>
                <p className={styles.level}>{props.trait.high_label.text}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default OceanTrait;
