import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

import Header from "../components/Header";
import Science from "../scenes/Science";

import {
  IPrismicHeader,
  IPrismicFooter,
  IPrismicScience,
  IPrismicPersonalityTest,
  IPrismicMotivationTest,
  IPrismicIqTest,
} from "../scenes/prismic.model";
import Footer from "../components/Footer";
import { FAVICON } from "../utils/constants";

interface IProps {
  data: {
    prismicHeader: {
      data: IPrismicHeader;
    };
    prismicScience: {
      data: IPrismicScience;
    };
    prismicPersonalityTest: {
      data: IPrismicPersonalityTest;
    };
    prismicMotivationTest: {
      data: IPrismicMotivationTest;
    };
    prismicIqTest: {
      data: IPrismicIqTest;
    };
    allPrismicOcean: {
      edges: any[];
    };
    allPrismicDrives: {
      edges: any[];
    };
    prismicFooter: {
      data: IPrismicFooter;
    };
  };
  pageContext: {
    locale: string;
    name: string;
  };
}

export default function SciencePage(props: IProps) {
  const sortFunction = (a: any, b: any) => a.ordinal_number - b.ordinal_number;
  const mapTraits = () => {
    return props.data.allPrismicOcean.edges
      .map((edge: any) => edge.node.data)
      .sort(sortFunction);
  };
  const mapDrives = () => {
    return props.data.allPrismicDrives.edges
      .map((edge: any) => edge.node.data)
      .sort(sortFunction);
  };
  return (
    <>
      <Helmet>
        <title>matchHR</title>
        <link rel="shortcut icon" href={FAVICON} type="image/x-icon" />
      </Helmet>
      <Header
        data={props.data.prismicHeader.data}
        pageContext={props.pageContext}
      />
      <Science
        scienceData={props.data.prismicScience.data}
        personalityTestData={props.data.prismicPersonalityTest.data}
        motivationTestData={props.data.prismicMotivationTest.data}
        iqTestData={props.data.prismicIqTest.data}
        traits={mapTraits()}
        drives={mapDrives()}
      />
      <Footer data={props.data.prismicFooter.data} />
    </>
  );
}

export const pageQuery = graphql`
  query prismicHowItWorksQuery($locale: String!) {
    prismicHeader(lang: { eq: $locale }) {
      data {
        how_it_works_link {
          text
        }
        pricing_link {
          text
        }
        contact_us_link {
          text
        }
        about_us_link {
          text
        }
        login_link {
          text
        }
        signup_link {
          text
        }
        book_demo_link {
          text
        }
        get_started_link {
          text
        }
      }
    }
    prismicScience(lang: { eq: $locale }) {
      data {
        intro_heading {
          text
        }
        intro_label {
          text
        }
        intro_illustration {
          url
        }
      }
    }
    prismicPersonalityTest(lang: { eq: $locale }) {
      data {
        headline {
          text
        }
        paragraph_1 {
          text
        }
        paragraph_2 {
          text
        }
        personality_test_illustration {
          url
        }
      }
    }
    prismicMotivationTest(lang: { eq: $locale }) {
      data {
        headline {
          text
        }
        paragraph_1 {
          text
        }
        paragraph_2 {
          text
        }
        paragraph_3 {
          text
        }
        paragraph_4 {
          text
        }
        motivation_test_illustration {
          url
        }
      }
    }
    prismicIqTest(lang: { eq: $locale }) {
      data {
        headline {
          text
        }
        paragraph_1 {
          text
        }
        paragraph_2 {
          text
        }
        paragraph_3 {
          text
        }
        iq_test_illustration {
          url
        }
      }
    }
    allPrismicOcean(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            title {
              text
            }
            what_does_it_mean_label {
              text
            }
            explanation {
              text
            }
            example {
              text
            }
            jobs_headline {
              text
            }
            low_label {
              text
            }
            medium_label {
              text
            }
            high_label {
              text
            }
            low_job {
              text
            }
            medium_job {
              text
            }
            high_job {
              text
            }
            ordinal_number
          }
        }
      }
    }
    allPrismicDrives(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          data {
            title {
              text
            }
            explanation {
              text
            }
            color {
              text
            }
            ordinal_number
          }
        }
      }
    }
    prismicFooter(lang: { eq: $locale }) {
      data {
        footer_heading {
          text
        }
        footer_label {
          text
        }
        get_started_button {
          text
        }
        copyright {
          text
        }
        contact_us_link {
          text
        }
        privacy_policy_link {
          text
        }
      }
    }
  }
`;
